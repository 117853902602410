@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';
@import '../../../assets/scss/buttons.scss';

.main-container-services {
  padding: 150px 0 75px 0;
  position: relative;
  background-color: $color-background;
  width: 100%;

  .success-message {
    position: absolute;
    top: 20%;
    left: 50%;
    z-index: 1001;
    background-color: $color-primary;
    color: $color-white;
    border-radius: 3px;
    border: 4px double $color-white;
    padding: 10px 20px;
    transform: translate(-50%, -50%);
  }

  .container-services {
    width: 100%;
    max-width: 1230px;
    margin: 0 auto;
    padding: 0 15px;

    .custom-field {
      border: 2px $color-secondary-light solid;
      border-top: none;
      margin: 32px 2px;
      padding: 8px;

      h1 {
        margin: -16px -8px 0;

        &:before {
          border-top: 2px $color-secondary-light solid;
          content: ' ';
          float: left;
          margin: 8px 2px 0 -1px;
          width: 12px;
        }

        &:after {
          border-top: 2px $color-secondary-light solid;
          content: ' ';
          display: block;
          height: 24px;
          left: 2px;
          margin: 0 1px 0 0;
          overflow: hidden;
          position: relative;
          top: 8px;
        }

        span {
          float: left;
          font: 40px normal;
          line-height: 0.5;
          padding-bottom: 50px;
        }
      }
    }

    .services-wrapper {
      background-color: $color-background;
      width: 100%;
      min-height: 600px;
      display: grid;
      place-items: center;
      gap: 0;
      margin-bottom: 30px;

      .center-container {
        background-color: $color-background;
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: flex-start;
        flex-wrap: wrap;
        border-radius: 20px;
        padding: 0 20px;
        gap: 16px;

        .services {
          position: relative;
          background-color: $color-background;
          border-radius: 20px;
          min-height: 260px;
          width: calc(25% - 12px);
          background-size: cover;
          box-shadow: 0 0 10px rgba(0, 0, 255, .3);
          transition: transform 0.3s ease-in-out;
          cursor: pointer;

          &:hover {
            transform: scale(1.03);
          }

          h4 {
            color: $color-white;
            text-shadow: -1px 0 $color-black, 0 1px $color-black, 1px 0 $color-black, 0 -1px $color-black;
            text-transform: uppercase;
            position: absolute;
            top: 80%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .service-first {
          background-image: linear-gradient(rgba(0, 0, 0, 0.3),
              rgba(0, 0, 0, 0.5)), url("../../../assets/images/trucks/sale-greyscale.jpg");
          background-position: left 100% bottom 50%;
        }

        .service-second {
          background-image: linear-gradient(rgba(0, 0, 0, 0),
              rgba(0, 0, 0, 0.2)), url("../../../assets/images/index/road-grey-scale.jpg");
          background-position: left 35% bottom 50%;
        }

        .service-third {
          background-image: linear-gradient(rgba(0, 0, 0, 0.2),
              rgba(0, 0, 0, 0.4)), url("../../../assets/images/index/service-greyscale.jpg");
          background-position: left 50% bottom 50%;
        }

        .service-fourth {
          background-image: linear-gradient(rgba(0, 0, 0, 0.2),
              rgba(0, 0, 0, 0.4)), url("../../../assets/images/trucks/scania-greyscale.jpg");
          background-position: left 80% bottom 50%;
        }

        .service-fifth {
          background-image: linear-gradient(rgba(0, 0, 0, 0),
              rgba(0, 0, 0, 0.2)), url("../../../assets/images/index/europe-photo-grey-scale.jpg");
        }

        .service-sixth {
          background-image: linear-gradient(rgba(0, 0, 0, 0),
              rgba(0, 0, 0, 0.2)), url("../../../assets/images/personnel/consulting-greyscale.jpg");
          background-position: left 0 bottom 0;
        }
      }
    }
  }

  .success-message {
    position: absolute;
    top: 20%;
    left: 50%;
    z-index: 1001;
    background-color: $color-primary;
    color: $color-white;
    border-radius: 3px;
    border: 4px double $color-white;
    padding: 10px 20px;
    transform: translate(-50%, -50%);
  }
}

.background-container-services {
  width: 100%;
  background-color: $color-background;

  .container {
    width: 100%;
    max-width: 1230px;
    margin: 50px auto;
    padding: 0 15px;

    .services-form-wrapper {
      padding: 25px;

      .services-form {
        display: flex;
        flex-wrap: wrap;
        column-gap: 30px;
        row-gap: 10px;

        .services-input-wrapper {
          width: calc(50% - 15px);

          select {
            line-height: 3;
            padding-left: 20px;
            margin-bottom: 5px;
            border-radius: 5px;
            width: 100%;
            appearance: none;
            background-color: $color-white;
            border: none;
            z-index: 1;
          }
        }

        .name-input-wrapper {
          width: calc(50% - 15px);

          input {
            line-height: 3;
            padding-left: 20px;
            margin-bottom: 5px;
            border-radius: 5px;
            border: none;
            width: 100%;
          }
        }

        .phone-input-wrapper {
          width: calc(50% - 15px);

          input {
            line-height: 3;
            padding-left: 20px;
            margin-bottom: 5px;
            border-radius: 5px;
            border: none;
            width: 100%;
          }
        }

        .mail-service-wrapper {
          width: calc(50% - 15px);

          input {
            line-height: 3;
            padding-left: 20px;
            margin-bottom: 5px;
            border-radius: 5px;
            border: none;
            width: 100%;
          }
        }

        .textarea-service-wrapper {
          width: 100%;

          textarea {
            padding: 25px;
            margin-bottom: 5px;
            border-radius: 5px;
            border: none;
            width: 100%;
          }
        }

        .buttons-service-wrapper {
          width: 100%;
          display: flex;
          gap: 50px;
          align-items: center;
          justify-content: center;

          button {
            width: 200px;
          }
        }
      }
    }
  }
}

@include screen-responsive($screen-xxl) {}

@include screen-responsive($screen-xl) {
  .main-container-services {
    .container-services {
      .services-wrapper {
        .center-container {
          .services {
            width: calc(50% - 8px);
          }
        }
      }
    }
  }
}

@include screen-responsive($screen-lg) {}

@include screen-responsive($screen-md) {
  .main-container-services {
    .container-services {
      .services-wrapper {
        .center-container {
          .services {
            width: 100%;
            min-height: 300px;
          }
        }
      }
    }
  }

  .background-container-services {
    .container {
      .services-form-wrapper {
        .services-form {
          .services-input-wrapper {
            width: 100%;
          }

          .name-input-wrapper {
            width: 100%;
          }

          .phone-input-wrapper {
            width: 100%;
          }

          .mail-service-wrapper {
            width: 100%;
          }
        }
      }
    }
  }
}

@include screen-responsive($screen-sm) {}