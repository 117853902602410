@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';
@import 'node_modules/sass-math/math.scss';

$grid-background-color: #000;
$grid-line-color: #0F0F0F;
$grid-width: 100px;
$timeline-events-no: 11;
$timeline-events-line-height: 10px;
$timeline-years-no: 3;

.background-container-timeline {
  width: 100%;
  background-color: $grid-background-color;

  .container {
    width: 100%;
    max-width: 1230px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;

    .timeline-wrapper {
      position: relative;
      width: 100%;
      min-height: 700px;
      font-family: sans-serif;
      margin: 80px 0 0 0;
      padding: 0;
      background: linear-gradient(90deg, $grid-background-color 0, $grid-background-color $grid-width - 1, $grid-line-color $grid-width);
      background-size: $grid-width $grid-width;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        height: 14px;
        background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(194, 227, 78) 50%);
      }

      &::-webkit-scrollbar-thumb {
        background: linear-gradient(90deg, rgba(86, 194, 243) 0%, rgb(255, 255, 255) 50%);
      }

      h1 {
        position: absolute;
        top: 60px;
        left: 20px;
        color: #fff;
        font-weight: normal;
        font-size: 28px;
        text-transform: uppercase;
      }

      .timelines-years {
        position: absolute;
        top: 500px;
        padding: 0 0 0 #{$grid-width * 3};
        margin: 0;
        white-space: nowrap;
        border-top: 1px solid #282828;
        list-style: none;
        font-size: 0;

        li {
          position: relative;
          top: -6px;
          display: inline-block;
          width: #{$grid-width * 3};
          color: #868686;
          font-size: 11px;
          line-height: 11px;
          text-indent: -12px;

          &:last-child {
            width: $grid-width;
          }
        }
      }

      .timeline-events {
        position: absolute;
        top: 350px;
        padding-bottom: 50px;
        list-style: none;
        white-space: nowrap;
        font-size: 0;

        h2,
        h3,
        h4 {
          margin: 0 0 1px 0;
          font-weight: normal;
          font-size: 16px;
        }

        h2 {
          color: #777;
          text-transform: uppercase;
        }

        h4 {
          color: #fff;
          font-style: italic;
        }
      }

      .timeline-events {
        li {
          position: relative;
          display: inline-block;

          &:before {
            position: absolute;
            left: 0;
            bottom: -36px;
            height: $timeline-events-line-height;
            border-radius: $timeline-events-line-height;
            content: '';
          }
        }
      }

      @for $i from 1 through $timeline-events-no {
        .timeline-events li:nth-child(#{$i}) {
          bottom: #{((($timeline-events-line-height * 2) * $i)) - ($timeline-events-line-height * 2)};
        }
      }

      .timeline-events li:nth-child(1):before {
        background-color: #C2E34E;
        background-image: linear-gradient(to right, $grid-background-color 0, #C2E34E);
        border-radius: 0 6px 6px 0;
      }

      .timeline-events li:nth-child(1) h3 {
        color: #C2E34E;
      }

      .timeline-events li:nth-child(2):before {
        background: #FF9704;
      }

      .timeline-events li:nth-child(2) h3 {
        color: #FF9704;
      }

      .timeline-events li:nth-child(3):before {
        background: #56C2F3;
      }

      .timeline-events li:nth-child(3) h3 {
        color: #56C2F3;
      }

      .timeline-events li:nth-child(4):before {
        background: #DD3D01;
      }

      .timeline-events li:nth-child(4) h3 {
        color: #DD3D01;
      }

      .timeline-events li:nth-child(5):before {
        background-color: #C2E34E;
      }

      .timeline-events li:nth-child(5) h3 {
        color: #C2E34E;
      }

      .timeline-events li:nth-child(6):before {
        background: #FF9704;
      }

      .timeline-events li:nth-child(6) h3 {
        color: #FF9704;
      }

      .timeline-events li:nth-child(7):before {
        background: #56C2F3;
      }

      .timeline-events li:nth-child(7) h3 {
        color: #56C2F3;
      }

      .timeline-events li:nth-child(8):before {
        background: #DD3D01;
      }

      .timeline-events li:nth-child(8) h3 {
        color: #DD3D01;
      }

      .timeline-events li:nth-child(9):before {
        background-color: #C2E34E;
      }

      .timeline-events li:nth-child(9) h3 {
        color: #C2E34E;
      }

      .timeline-events li:nth-child(10):before {
        background: #FF9704;
      }

      .timeline-events li:nth-child(10) h3 {
        color: #FF9704;
      }

      .timeline-events li:nth-child(11):before {
        background: #56C2F3;
      }

      .timeline-events li:nth-child(11) h3 {
        color: #56C2F3;
      }


      @for $i from 0 through $timeline-years-no {

        .timeline-event-years-#{$i},
        .timeline-event-years-#{$i}:before {
          width: #{$grid-width * $i};
        }

        .timeline-event-years-#{$i}-5,
        .timeline-event-years-#{$i}-5:before {
          width: ($grid-width * $i) + calc($grid-width / 2);
        }
      }

      .timeline-event-legend {
        position: relative;
      }

      .timeline-event-legend span {
        position: absolute;
        bottom: -150px;
        left: 850px;
      }

      .timeline-event-legend h2,
      .timeline-event-legend h3,
      .timeline-event-legend h4 {
        display: inline;
        margin-right: 10px;
      }

      .timeline-event-legend i {
        position: absolute;
        width: 0;
        height: 0;
        border: calc($timeline-events-line-height / 2) solid transparent;
        border-bottom-color: #fff;
      }

      .timeline-event-legend i:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border: calc($timeline-events-line-height / 2) solid transparent;
        border-top-color: #fff;
      }

      .timeline-event-legend>i {
        bottom: -32px;
        right: -12px;
      }

      .timeline-event-legend>i:after {
        left: -(calc($timeline-events-line-height / 2));
        bottom: -12px;
      }

      .timeline-event-legend span>i {
        top: -1px;
        left: -14px;
      }

      .timeline-event-legend span>i:after {
        left: -4px;
        bottom: -12px;
      }
    }
  }
}

.background-container-timeline + .footer-container {
  box-shadow: none;
}

@include screen-responsive($screen-xxl) {}

@include screen-responsive($screen-xl) {}

@include screen-responsive($screen-lg) {}

@include screen-responsive($screen-md) {
    .background-container-timeline {
        .container {
            .timeline-wrapper {
                background: linear-gradient(180deg, $grid-background-color 0, $grid-background-color $grid-width - 1, $grid-line-color $grid-width);
                background-size: $grid-width $grid-width;
                min-height: 900px;
                overflow-y: hidden;
                overflow-x: hidden;

                h1 {
                    top: 20px;
                    left: 20px;
                }

                .timeline-events {
                    display: flex;
                    flex-direction: column-reverse;
                    top: 285px;
                    left: 0;

                    li {
                        &:before {
                            bottom: -13px;
                            height: $timeline-events-line-height;
                            border-radius: $timeline-events-line-height;
                            content: '';
                        }

                        &:nth-child(1) {
                            &:before {
                                display: none;
                            }
                        }
                    }
                }

                .timelines-years {
                    display: none;
                }
            }
        }
    }
}

@include screen-responsive($screen-sm) {}