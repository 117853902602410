@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixins.scss';

.main-header {
    display: grid;
    align-content: center;
    gap: 0;
    background-color: #FFFFFF60;
    position: fixed;
    width: 100%;
    top: 0;
    min-height: 80px;
    z-index: 999;
    box-shadow: 0 5px 20px rgba(0, 0, 255, .2);

    .container {
        width: 100%;
        height: 100%;
        max-width: 1230px;
        margin: 0 auto;
        padding: 0 15px;

        .main-header-content-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .logo-wrapper {

                a {
                    img {

                        &:nth-child(1) {
                            height: 60px;
                            width: 300px;
                        }

                        &:nth-child(2) {
                            display: none;
                            height: 60px;
                            width: 96px;
                        }
                    }
                }

                .hamburger {
                    display: none;
                    cursor: pointer;

                    .bar {
                        display: block;
                        width: 25px;
                        height: 3px;
                        margin: 5px auto;
                        transition: all 0.3s ease;
                        background-color: $color-black;
                    }
                }
            }

            .main-navigation {
                display: flex;
                column-gap: 30px;
                margin-left: 30px;

                ul.main-menu {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                    align-content: center;
                    list-style-type: none;
                    padding-left: 0;
                    margin-bottom: 0;

                    li.menu-item {
                        padding: 0;

                        a.nav-link {
                            display: block;
                            color: $color-black;
                            text-shadow: -1px 0 $color-white, 0 1px $color-white, 1px 0 $color-white, 0 -1px $color-white;
                            font-weight: 600;
                            text-decoration: none;
                            padding: 26px 16px;
                            border-bottom: 4px solid transparent;
                            transition: 0.3s ease-out;

                            &:hover {
                                border-bottom: 4px solid $color-secondary-light;
                                background-color: $color-background;
                            }

                            .languages-wrapper {
                                .lithuanian {
                                    display: none;
                                }
                            }
                        }


                    }
                }

                .contact-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    align-content: center;
                    justify-content: center;
                    gap: 10px;
                }
            }
        }
    }
}

@include screen-responsive($screen-xl) {
    body {
        .main-header {
            .container {
                .main-header-content-wrapper {
                    .logo-wrapper {
                        a {
                            img {
                                &:nth-child(1) {
                                    display: none;
                                }

                                &:nth-child(2) {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include screen-responsive($screen-lg) {
    body {
        .main-header {
            .container {
                .main-header-content-wrapper {
                    .logo-wrapper {
                        a {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@include screen-responsive($screen-md) {
    body {
        .main-header {
            .container {
                .main-header-content-wrapper {
                    .logo-wrapper {
                        .hamburger {
                            display: block;
                        }

                        .hamburger.active {
                            .bar:nth-child(2) {
                                opacity: 0;
                            }
                        }

                        .hamburger.active {
                            .bar:nth-child(1) {
                                transform: translateY(8px) rotate(45deg);
                            }
                        }

                        .hamburger.active {
                            .bar:nth-child(3) {
                                transform: translateY(-8px) rotate(-45deg);
                            }
                        }
                    }

                    .main-navigation {
                        ul.main-menu {
                            position: fixed;
                            left: -100%;
                            top: 80px;
                            gap: 0;
                            flex-direction: column;
                            background-color: #FFFFFF80;
                            width: 100%;
                            text-align: center;
                            transition: 0.3s;

                            &.active {
                                left: 0;
                            }

                            li.menu-item {
                                width: 100%;

                                a.nav-link {
                                    &:hover {
                                        background-color: transparent;
                                        border-bottom-color: transparent;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}