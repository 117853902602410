@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';
@import '../../../assets/scss/buttons.scss';

.main-container-career {
    position: relative;
    background-color: $color-background;
    width: 100%;
    padding: 150px 0 100px 0;

    .container-career {
        width: 100%;
        max-width: 1230px;
        margin: 0 auto;
        padding: 0 15px;

        .custom-field {
            border: 2px $color-secondary-light solid;
            border-top: none;
            margin: 32px 2px;
            padding: 8px;

            h1 {
                margin: -16px -8px 0;

                &:before {
                    border-top: 2px $color-secondary-light solid;
                    content: ' ';
                    float: left;
                    margin: 8px 2px 0 -1px;
                    width: 12px;
                }

                &:after {
                    border-top: 2px $color-secondary-light solid;
                    content: ' ';
                    display: block;
                    height: 24px;
                    left: 2px;
                    margin: 0 1px 0 0;
                    overflow: hidden;
                    position: relative;
                    top: 8px;
                }

                span {
                    float: left;
                    font: 40px normal;
                    line-height: 0.5;
                    padding-bottom: 50px;
                }
            }
        }

        .career-box-wrapper {
            background-color: $color-background;
            width: 100%;
            height: 100%;
            display: grid;
            place-items: center;
            gap: 0;

            .center-container {
                background-color: $color-background;
                display: flex;
                height: 100%;
                width: 100%;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                border-radius: 20px;
                gap: 16px;
                padding: 0 20px 30px;

                .career-box {
                    display: flex;
                    flex-wrap: wrap;
                    position: relative;
                    background-color: $color-background;
                    border-radius: 20px;
                    min-height: 260px;
                    width: calc(25% - 12px);
                    background-size: cover;
                    box-shadow: 0 0 10px rgba(0, 0, 255, .3);
                    transition: transform 0.3s ease-in-out;
                    cursor: pointer;

                    &:hover {
                        transform: scale(1.03);
                    }

                    .text-box {
                        h4 {
                            position: absolute;
                            color: $color-white;
                            text-shadow: -1px 0 $color-black, 0 1px $color-black, 1px 0 $color-black, 0 -1px $color-black;
                            text-transform: uppercase;
                            top: 80%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }

                        h6 {
                            position: absolute;
                            text-transform: capitalize;
                            color: $color-white;
                            text-shadow: -1px 0 $color-black, 0 1px $color-black, 1px 0 $color-black, 0 -1px $color-black;
                            top: 90%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }
                }
            }

            .box-first {
                background-image: linear-gradient(rgba(0, 0, 0, 0.3),
                        rgba(0, 0, 0, 0.4)), url("../../../assets/images/personnel/driver3_greyscale.jpg");
                background-position: left 35% bottom 50%;
            }

            .box-second {
                background-image: linear-gradient(rgba(0, 0, 0, 0.3),
                        rgba(0, 0, 0, 0.4)), url("../../../assets/images/personnel/driver_greyscale.jpg");
                background-position: left 25% bottom 50%;
            }

            .box-third {
                background-image: linear-gradient(rgba(0, 0, 0, 0.3),
                        rgba(0, 0, 0, 0.4)), url("../../../assets/images/personnel/manager_greyscale.jpg");
            }

            .box-fourth {
                background-image: linear-gradient(rgba(0, 0, 0, 0.3),
                        rgba(0, 0, 0, 0.4)), url("../../../assets/images/personnel/working_place_greyscale.jpg");
            }
        }
    }
}

.background-container-form {
    width: 100%;
    background-color: $color-background;

    .container {
        background-color: $color-background;
        width: 100%;
        max-width: 1230px;
        margin: 50px auto;
        border-radius: 5px;
        padding: 0 15px;

        .form-wrapper {
            position: relative;
            padding: 25px;

            .success-message {
                position: absolute;
                top: 20%;
                left: 50%;
                z-index: 1001;
                background-color: $color-primary;
                color: $color-white;
                border-radius: 3px;
                border: 4px double $color-white;
                padding: 10px 20px;
                transform: translate(-50%, -50%);
            }

            .career-form {
                display: flex;
                flex-wrap: wrap;
                column-gap: 30px;
                row-gap: 10px;

                .first-name-input-wrapper {
                    width: calc(50% - 15px);

                    input {
                        line-height: 3;
                        padding-left: 20px;
                        margin-bottom: 5px;
                        border-radius: 5px;
                        border: none;
                        width: 100%;
                    }
                }

                .last-name-input-wrapper {
                    width: calc(50% - 15px);

                    input {
                        line-height: 3;
                        padding-left: 20px;
                        margin-bottom: 5px;
                        border-radius: 5px;
                        border: none;
                        width: 100%;
                    }
                }

                .tel-input-wrapper {
                    width: calc(50% - 15px);

                    input {
                        line-height: 3;
                        padding-left: 20px;
                        margin-bottom: 5px;
                        border-radius: 5px;
                        border: none;
                        width: 100%;
                    }
                }

                .mail-input-wrapper {
                    width: calc(50% - 15px);

                    input {
                        line-height: 3;
                        padding-left: 20px;
                        margin-bottom: 5px;
                        border-radius: 5px;
                        border: none;
                        width: 100%;
                    }
                }

                .position-input-wrapper {
                    width: calc(50% - 15px);

                    select {
                        line-height: 3;
                        padding-left: 20px;
                        margin-bottom: 5px;
                        border-radius: 5px;
                        width: 100%;
                        appearance: none;
                        background-color: $color-white;
                        border: none;
                        z-index: 1;
                    }
                }

                .experience-input-wrapper {
                    width: calc(50% - 15px);

                    input {
                        line-height: 3;
                        padding-left: 20px;
                        margin-bottom: 5px;
                        border-radius: 5px;
                        border: none;
                        width: 100%;
                    }
                }

                .textarea-input-wrapper {
                    width: 100%;

                    textarea {
                        padding: 25px;
                        margin-bottom: 5px;
                        border-radius: 5px;
                        border: none;
                        width: 100%;
                    }
                }

                .buttons-career-wrapper {
                    width: 100%;
                    display: flex;
                    gap: 50px;
                    align-items: center;
                    justify-content: center;

                    button {
                        width: 200px;
                    }
                }
            }
        }
    }
}

@include screen-responsive($screen-lg) {

    .main-container-career {
        .container-career {
            .career-box-wrapper {
                .center-container {
                    .career-box {
                        width: calc(50% - 8px);
                    }
                }
            }
        }
    }

    .background-container-form {
        .container {
            .form-wrapper {
                .career-form {
                    .first-name-input-wrapper {
                        width: 100%;
                    }

                    .last-name-input-wrapper {
                        width: 100%;
                    }

                    .tel-input-wrapper {
                        width: 100%;
                    }

                    .mail-input-wrapper {
                        width: 100%;
                    }

                    .position-input-wrapper {
                        width: 100%;
                    }

                    .experience-input-wrapper {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@include screen-responsive($screen-sm) {

    .main-container-career {
        .container-career {
            .career-box-wrapper {
                .center-container {
                    .career-box {
                        width: calc(100%);
                    }
                }
            }
        }
    }
}