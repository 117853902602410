@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.footer-container {
    background-color: $color-black;
    width: 100%;
    box-shadow: 0 -5px 20px rgba(0, 0, 255, .2);

    .container {
        width: 100%;
        max-width: 1230px;
        margin: 0 auto;
        padding: 0 15px;

        .footer-content-wrapper {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            color: $color-text;

            .logo-wrapper {
                width: 50%;
                min-height: 50px;
            }

            .social-media-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                margin-top: 50px;
                min-height: 160px;
                border-top: 1px lightgrey solid;
                box-shadow: 0 1px 0 rgba(0, 0, 255, .2), inset 0 1px 0 rgba(0, 0, 255, .2);

                .social-media {
                    display: flex;
                    justify-content: space-between;
                    width: 200px;

                    .top-logos {
                        a {
                            svg {
                                transition: transform .2s;

                                path {
                                    fill: $color-text;
                                    transition: fill .3s ease-in-out;
                                }
                            }
                        }

                        a {
                            &:hover {
                                svg {
                                    transform: scale(1.1);

                                    path {
                                        fill: $color-primary;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .structure-contact-wrapper {
                display: flex;
                width: 100%;
                min-height: 380px;
                align-items: center;
                border-top: 1px lightgrey solid;
                border-bottom: 1px lightgrey solid;
                box-shadow: 0 1px 0 rgba(0, 0, 255, .2), inset 0 1px 0 rgba(0, 0, 255, .2);

                .structure-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    width: 40%;

                    h5 {
                        width: 100%;
                        min-height: 70px;
                        text-transform: uppercase;
                        font-weight: bold;
                        letter-spacing: 2px;
                        margin-bottom: 0;
                    }

                    .menu-duplicate {
                        width: 30%;

                        ul {
                            list-style-type: none;
                            padding-left: 0;

                            li {
                                line-height: 2;


                                a {
                                    text-decoration: none;
                                    color: $color-text;
                                    transition: color 0.3s ease-out;

                                    &:hover {
                                        color: $color-secondary;
                                    }
                                }
                            }
                        }
                    }

                    .interesting-links {
                        width: 70%;

                        ul {
                            list-style-type: none;
                            padding-left: 0;

                            li {
                                line-height: 2;

                                a {
                                    text-decoration: none;
                                    color: $color-text;
                                    transition: color 0.3s ease-out;

                                    &:hover {
                                        color: $color-secondary;
                                    }
                                }
                            }
                        }
                    }
                }

                .contact-information {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    width: 60%;

                    h5 {
                        width: 100%;
                        min-height: 115px;
                        text-transform: uppercase;
                        font-weight: bold;
                        letter-spacing: 2px;
                        margin-bottom: 0;
                    }

                    .address-info {
                        width: 23%;

                        h6 {
                            text-transform: uppercase;
                            font-weight: bold;
                            letter-spacing: 1px;
                            padding-bottom: 20px;
                        }

                        address {
                            ul {
                                list-style-type: none;
                                padding-left: 0;

                                li {
                                    line-height: 2;

                                    a {
                                        text-decoration: none;
                                        color: $color-text;
                                        transition: color 0.3s ease-out;
                                    }
                                }
                            }

                            &:hover {
                                ul {
                                    li {
                                        a {
                                            color: $color-secondary;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .contacts {
                        width: 32%;

                        h6 {
                            text-transform: uppercase;
                            font-weight: bold;
                            letter-spacing: 1px;
                            padding-bottom: 20px;
                        }

                        ul {
                            list-style-type: none;
                            padding-left: 0;

                            li {
                                line-height: 2;

                                a {
                                    text-decoration: none;
                                    color: $color-text;
                                    transition: color 0.3s ease-out;

                                    &:hover {
                                        color: $color-secondary;
                                    }
                                }
                            }
                        }
                    }

                    .company-data {
                        width: 40%;

                        h6 {
                            text-transform: uppercase;
                            font-weight: bold;
                            letter-spacing: 1px;
                            padding-bottom: 20px;
                        }

                        ul {
                            list-style-type: none;
                            padding-left: 0;

                            li {
                                line-height: 2;
                            }
                        }
                    }
                }
            }

            .copyright {
                display: flex;
                align-items: flex-end;
                width: 50%;
                min-height: 50px;
            }

            .creator {
                display: flex;
                align-items: flex-end;
                width: 50%;
                min-height: 50px;
                justify-content: flex-end;

                span {
                    font-family: 'Stick No Bills', sans-serif;
                    color: $color-text;
                    font-weight: 600;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    font-size: 20px;
                    transition: color 1s ease-in-out;

                    a {
                        text-decoration: none;
                    }

                    .g,
                    .i,
                    .t {
                        font-size: 24px;
                    }

                    .i,
                    .t {
                        font-weight: 500;
                    }

                    .i {
                        letter-spacing: 3px;
                    }

                    .v {
                        letter-spacing: 4px;
                    }

                    &:hover {

                        .g {
                            color: $color-primary;
                        }

                        .r {

                            color: $color-secondary-light;
                        }

                        .a {
                            color: $color-primary-lighter;
                        }

                        .v {
                            color: $color-secondary-lighter;
                        }

                        .i {
                            color: $color-primary;
                        }

                        .t {
                            color: $color-secondary;
                        }
                    }
                }
            }
        }
    }
}

@include screen-responsive($screen-xl) {
    body {
        .footer-container {
            .container {
                .footer-content-wrapper {
                    .structure-contact-wrapper {
                        flex-direction: column;
                        align-items: flex-start;
                        row-gap: 50px;
                        padding-top: 50px;

                        .structure-wrapper,
                        .contact-information {
                            width: 100%;

                            h5 {
                                min-height: 70px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include screen-responsive($screen-md) {
    body {
        .footer-container {
            .container {
                .footer-content-wrapper {
                    .structure-contact-wrapper {
                        flex-direction: column;
                        align-items: flex-start;
                        row-gap: 30px;
                        padding: 60px 0 30px 0;

                        .structure-wrapper,
                        .contact-information {
                            width: 100%;
                            row-gap: 15px;

                            h5 {
                                max-height: 30px;
                            }
                        }

                        .contact-information {
                            .address-info {
                                width: 50%;
                            }

                            .contacts {
                                width: 50%;
                            }

                            .company-data {
                                width: 100%;
                            }
                        }
                    }

                    .copyright {
                        width: 100%;
                        min-height: 70px;
                    }

                    .creator {
                        width: 100%;
                        justify-content: flex-start;
                        min-height: 0;
                    }
                }
            }
        }
    }
}