$color-primary-dark: #006400;
$color-primary: #4CAF50;
$color-primary-light: #32cd32;
$color-primary-lighter: #90ee90;
$color-secondary: #008CBA;
$color-secondary-light: cornflowerblue;
$color-secondary-lighter: lightblue;
$color-white: rgb(255, 255, 255);
$color-black: rgb(0, 0, 0);
$color-background: #F0F0F0;
$color-text: #F0F0F0;

$screen-xxl: 1320px;
$screen-xl: 1140px;
$screen-lg: 960px;
$screen-md: 720px;
$screen-sm: 540px;